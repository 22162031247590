import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-368b90b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "thumbnail" }
const _hoisted_3 = { class: "detail" }
const _hoisted_4 = { class: "user-info" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "tstamp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImgCircle = _resolveComponent("BaseImgCircle")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseImgCircle, {
        size: "30px",
        src: 
          _ctx.thumbnail ? _ctx.thumbnail : require('@/assets/images/account_default.png')
        
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, { to: "#" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.nickname), 1),
              _createTextVNode(" 의 댓글 ")
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", null, _toDisplayString(_ctx.content), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(_ctx.timeAgo), 1)
          ])
        ]),
        _: 1
      })
    ])
  ]))
}