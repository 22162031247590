import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-697193cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-item" }
const _hoisted_2 = { class: "detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListCategoryItemLabel = _resolveComponent("ListCategoryItemLabel")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_ListCategoryItemLabel, { labelName: _ctx.categoryLabel }, null, 8, ["labelName"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.nickname), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.tstamp), 1)
    ]),
    _createElementVNode("article", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.content), 1)
    ])
  ]))
}