
import { defineComponent } from "vue";
import BaseImgCircle from "../BaseImgCircle.vue";

const __default__ = defineComponent({
  props: {
    thumbnail: {
      type: String,
      default: "",
    },
    nickname: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "var(--white-color)",
    },
    tstamp: {
      type: String,
      default: "",
    },
  },
  components: { BaseImgCircle },
  computed: {
    timeAgo() {
      return this.$timeAgo(this.$convertToDate(this.tstamp));
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4d1eaf25": (_ctx.backgroundColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__