import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-192db10a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["tab-left", { active: _ctx.activeTab === 'left' }])
    }, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClickTabLeft')))
      }, [
        _renderSlot(_ctx.$slots, "tab-left", {}, undefined, true)
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["tab-right", { active: _ctx.activeTab === 'right' }])
    }, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClickTabRight')))
      }, [
        _renderSlot(_ctx.$slots, "tab-right", {}, undefined, true)
      ])
    ], 2)
  ]))
}