
import { defineComponent } from "vue";
import ListCategoryItemLabel from "@/components/announcement/ListItemLabelCategory.vue";

const __default__ = defineComponent({
  props: {
    categoryLabel: {
      type: String,
      default: "",
    },
    nickname: {
      type: String,
      default: "",
    },
    tstamp: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "var(--white-color)",
    },
  },
  components: { ListCategoryItemLabel },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "42dd1430": (_ctx.backgroundColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__