import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54b500b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-item" }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListCategoryItemLabel = _resolveComponent("ListCategoryItemLabel")!
  const _component_ListCategoryItem = _resolveComponent("ListCategoryItem")!
  const _component_ListCategory = _resolveComponent("ListCategory")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_ListCategoryItemLabel, {
        class: "label",
        labelName: _ctx.categoryLabel
      }, null, 8, ["labelName"]),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("article", null, [
      _createVNode(_component_ListCategory, null, {
        default: _withCtx(() => [
          (_ctx.work_date)
            ? (_openBlock(), _createBlock(_component_ListCategoryItem, {
                key: 0,
                iconName: 'CalendarCheckOutline',
                description: _ctx.work_date
              }, null, 8, ["description"]))
            : _createCommentVNode("", true),
          (_ctx.pay)
            ? (_openBlock(), _createBlock(_component_ListCategoryItem, {
                key: 1,
                iconName: 'CurrencyKrw',
                description: _ctx.pay
              }, null, 8, ["description"]))
            : _createCommentVNode("", true),
          (_ctx.gender)
            ? (_openBlock(), _createBlock(_component_ListCategoryItem, {
                key: 2,
                iconName: 'HumanNonBinary',
                description: _ctx.gender
              }, null, 8, ["description"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}