
import { defineComponent } from "vue";
import BaseTabs from "@/components/BaseTabs.vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import List from "@/components/alerts/List.vue";
import ActivityListItem from "@/components/alerts/ActivityListItem.vue";
import KeywordsListItem from "@/components/alerts/KeywordsListItem.vue";
import KeywordsAnnouncementListItem from "@/components/alerts/KeywordsAnnouncementListItem.vue";
import { ActivityList, ListKeyword } from "@/components/alerts/index";
import LoadingIcon from "icons/Loading.vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

export default defineComponent({
  name: "Alert",
  components: {
    BaseTabs,
    BaseHeader2,
    List,
    ActivityListItem,
    KeywordsListItem,
    KeywordsAnnouncementListItem,
    LoadingIcon,
    InfiniteLoading,
  },
  data() {
    return {
      infiniteId: +new Date(),
      page: 1,
      alertsList: [] as ActivityList | ListKeyword,
      activeTab: "left" as "left" | "right",
      transTabs: {
        left: "keyword",
        right: "activity",
      },
      noReadBackgound: "var(--blue3-color)",
    };
  },
  methods: {
    itemBackground(isRead: boolean) {
      if (this.$isDarkMode()) {
        return isRead ? "var(--black1-color)" : "var(--grey3-color)";
      }
      return isRead ? "var(--white-color)" : "var(--blue3-color)";
    },
    onClickTab(tab: "left" | "right") {
      if (tab == "left") {
        this.$pushGAEvent("alert_keyword_click");
      } else {
        this.$pushGAEvent("alert_activity_click");
      }
      this.alertsList.splice(0, this.alertsList.length);
      this.page = 1;
      this.infiniteId++;
      this.activeTab = tab;
    },
    infiniteHandler($state: any) {
      this.load()
        .then((res: any) => {
          $state.loaded();
          if (this.alertsList.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.loaded();
          $state.complete();
        });
    },
    load() {
      const url = "/push/alert/" + this.transTabs[this.activeTab];
      return this.$axios
        .get(url, {
          params: {
            page: this.page,
          },
        })
        .then((result) => {
          let res = result.data.results;
          if (res.length > 0) {
            if (this.page == 1) {
              this.alertsList.splice(0, this.alertsList.length);
              this.alertsList.push(...res);
            } else {
              this.alertsList.push(...res);
            }
          }
          return result;
        });
    },
    async readAndGo(
      type: "activity" | "keyword",
      alertItem: {
        id: number;
        link: string;
        is_read: boolean;
        content_type?: string;
      }
    ) {
      if (type == "activity") {
        this.$pushGAEvent("alert_activity_detail_view", {
          type:
            alertItem.content_type &&
            alertItem.content_type.indexOf("urgent") >= 0
              ? "urgent"
              : "talkroom",
        });
      } else {
        this.$pushGAEvent("alert_keyword_detail_view", {
          type: alertItem.content_type,
        });
      }

      if (alertItem.is_read) {
        if (
          alertItem.content_type &&
          alertItem.content_type == "announcement" &&
          alertItem.link
        ) {
          this.$flutter.callHandler("launchUrl", {
            url: alertItem.link,
          });
        } else if (alertItem.link) {
          this.$router.push(alertItem.link);
        }
        return;
      }

      return await this.$axios
        .post("/push/alert/read", {
          alert_type: type,
          alert_id: alertItem.id,
        })
        .then(() => {
          alertItem.is_read = true;
          if (
            alertItem.content_type &&
            alertItem.content_type == "announcement" &&
            alertItem.link
          ) {
            this.$flutter.callHandler("launchUrl", {
              url: alertItem.link,
            });
          } else if (alertItem.link) {
            this.$router.push(alertItem.link);
          }
          return true;
        });
    },
  },
});
