
import { defineComponent } from "vue";
import ListCategory from "@/components/announcement/ListCategory.vue";
import ListCategoryItem from "@/components/announcement/ListItemCategory.vue";
import ListCategoryItemLabel from "@/components/announcement/ListItemLabelCategory.vue";

const __default__ = defineComponent({
  name: "KeywordsAnnouncementListItem",
  props: {
    title: {
      type: String,
      default: "",
    },
    categoryLabel: {
      type: String,
      default: "",
    },
    pay: {
      type: String,
      default: "",
    },
    work_date: {
      type: String,
      default: "",
    },
    gender: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "var(--white-color)",
    },
  },
  components: { ListCategoryItemLabel, ListCategory, ListCategoryItem },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "ac993c46": (_ctx.backgroundColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__