
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    activeTab: {
      type: String,
      validator(v: string) {
        return ["left", "right"].includes(v);
      },
      default() {
        return "left";
      },
    },
  },
});
